import moment from "moment";
import * as actionTypes from "../actions/ActionTypes";

const INITIAL_STATE = {
  sources: {
    1: {
      id: 1,
      urlPart: "invoiced-assignment/by-monthv2",
      scourceName: "lateCanceledAssignments",
      groupedData: [],
      data: [],
      crossFilterData: [],
      range: {
        from: moment().subtract(9, "month").toDate(),
        to: moment().subtract(1, "month").toDate(),
      },
    },
    2: {
      id: 2,
      urlPart: "invoiced-assignment/order-client-units",
      scourceName: "lateCanceledAssignments",
      groupedData: [],
      data: [],
      crossFilterData: [],
      range: {
        from: moment().subtract(9, "month").toDate(),
        to: moment().subtract(1, "month").toDate(),
      },
    },
    3: {
      id: 3,
      urlPart: "invoiced-assignment/late-canceled",
      scourceName: "lateCanceledAssignments",
      groupedData: [],
      data: [],
      crossFilterData: [],
      range: {
        from: moment().subtract(9, "month").toDate(),
        to: moment().subtract(1, "month").toDate(),
      },
    },
    4: {
      id: 4,
      urlPart: "invoiced-assignment/travel-time-allowance",
      scourceName: "travelTimeAllowances",
      groupedData: [],
      data: [],
      crossFilterData: [],
      range: {
        from: moment().subtract(9, "month").toDate(),
        to: moment().subtract(1, "month").toDate(),
      },
    },
    5: {
      id: 5,
      urlPart: "invoiced-assignment/pre-booked",
      scourceName: "preBookedAssignments",
      groupedData: [],
      data: [],
      crossFilterData: [],
      range: {
        from: moment().subtract(9, "month").toDate(),
        to: moment().subtract(1, "month").toDate(),
      },
    },
    6: {
      id: 6,
      urlPart: "invoiced-assignment/deviations",
      scourceName: "AssignmentDeviations",
      groupedData: [],
      data: [],
      crossFilterData: [],
      range: {
        from: moment().subtract(9, "month").toDate(),
        to: moment().subtract(1, "month").toDate(),
      },
    },
    7: {
      id: 7,
      urlPart: "ordered-assignment/fulfillmentsv2",
      scourceName: "AssignmnetFulfillment",
      groupedData: [],
      data: [],
      crossFilterData: [],
      range: {
        from: moment().subtract(9, "month").toDate(),
        to: moment().subtract(1, "month").toDate(),
      },
    },
    8: {
      id: 8,
      urlPart: "ordered-assignment/OrderPattern",
      scourceName: "OrderPatterns",
      groupedData: [],
      data: [],
      crossFilterData: [],
      range: {
        from: moment().subtract(9, "month").toDate(),
        to: moment().subtract(1, "month").toDate(),
      },
    },
    9: {
      id: 9,
      urlPart: "ordered-assignment/fulfillments",
      scourceName: "AssignmnetFulfillment",
      groupkey: "",
      data: [],
      groupedData: [],
      crossFilterData: [],
      range: {
        from: moment().subtract(9, "month").toDate(),
        to: moment().subtract(1, "month").toDate(),
      },
    },
    10: {
      id: 10,
      urlPart: "ordered-assignment/by-status",
      scourceName: "OrderStatusAssignment",
      data: [],
      groupedData: [],
      crossFilterData: [],
      range: {
        from: moment().subtract(9, "month").toDate(),
        to: moment().subtract(1, "month").toDate(),
      },
    },
  },
};

const app = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RESET_APP:
      return INITIAL_STATE;

    case actionTypes.SET_DATASOURCE: {
      const { data, range, sourceId } = action;
      const sourcesNewState = { ...state.sources };
      sourcesNewState[sourceId] = {
        ...sourcesNewState[sourceId],
        data,
        crossFilterData: data,
        range,
        id: sourceId,
      };

      return {
        ...state,
        sources: sourcesNewState,
      };
    }
    case actionTypes.SET_REPORT_DATASOURCE_RAW: {
      const { rawData, sourceId, groupColumnsKey } = action;
      if (rawData.length > 0 || groupColumnsKey) {
        const sourcesNewState = { ...state.sources };
        const groupedData = {
          ...(sourcesNewState[sourceId].groupedData || {}),
        };
        groupedData[groupColumnsKey] = [...(rawData || [])];
        sourcesNewState[sourceId] = {
          ...sourcesNewState[sourceId],
          groupedData,
        };
        return {
          ...state,
          sources: sourcesNewState,
        };
      }
      return {
        ...state,
      };
    }
    case actionTypes.SET_CROSS_FILTER: {
      const { sourceId, filter } = action;
      const sourcesNewState = { ...state.sources };
      sourcesNewState[sourceId] = {
        ...sourcesNewState[sourceId],
        crossFilterData: sourcesNewState[sourceId].data.filter(
          (x) => x[filter.key] === filter.value
        ),
      };
      return {
        ...state,
        sources: sourcesNewState,
      };
    }
    case actionTypes.REMOVE_CROSS_FILTER: {
      const { sourceId } = action;
      const sourcesNewState = { ...state.sources };
      sourcesNewState[sourceId] = {
        ...sourcesNewState[sourceId],
        crossFilterData: sourcesNewState[sourceId].data,
      };
      return {
        ...state,
        sources: sourcesNewState,
      };
    }

    default:
      return state;
  }
};

export default app;
