import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { convertStringToDate } from "Share/utils/dateUtil";
import { WidgetBasicCard } from "ReportPortal/components";

const processData = (data, category = "monthYear") =>
  [...data].sort((a, b) => {
    const first = convertStringToDate(a[category], "MMMM YYYY");
    const second = convertStringToDate(b[category], "MMMM YYYY");
    const returnValue = first.diff(second, "month");
    return returnValue;
  });

function InvoicedAssignmentWidget({ monthlyInterpretations, dataReadyFlag }) {
  const [data, setData] = useState(processData(monthlyInterpretations));
  useEffect(() => {
    setData(processData(monthlyInterpretations));
  }, [monthlyInterpretations]);
  return (
    <div className="exact-col-3" style={{ gridArea: "basicCard" }}>
      <WidgetBasicCard
        data={data}
        title="Totala fakturerat"
        field="invoicedAmount"
        category="monthYear"
        chartType={2}
        appendText="kr"
        isLoading={!dataReadyFlag}
      />
      <WidgetBasicCard
        data={data}
        title={"Antal fakturerat uppdrag"}
        field="noOfAssignments"
        category="monthYear"
        chartType={2}
        appendText=""
        isLoading={!dataReadyFlag}
      />
      <WidgetBasicCard
        data={data}
        title={"Total fakturerat tid"}
        field="timeSpend"
        category="monthYear"
        chartType={2}
        appendText="h"
        isLoading={!dataReadyFlag}
      />
    </div>
  );
}

InvoicedAssignmentWidget.propTypes = {
  dataReadyFlags: PropTypes.bool.isRequired,
  monthlyInterpretations: propTypes.arrayOf({}).isRequired,
};

const mapStateToProps = (state, props) => {
  const dataReadyFlags = [state.reportDataSources[1].dataReadyFlags[0]];
  return {
    dataReadyFlag: dataReadyFlags[0] || dataReadyFlags.length !== 0 || false,
    monthlyInterpretations: [
      ...(state.reportDataSources[1].crossFilterData[0] || []),
    ],
  };
};
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicedAssignmentWidget);
