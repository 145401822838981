import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './style.scss';

const NavigationLink = ({ text, to, onClick, className, ...props }) => (
  <NavLink
    {...props}
    to={to}
    activeClassName="nav-item--active"
    className={`${className}`}
    onClick={onClick}
  >
    {text}
  </NavLink>
);

NavigationLink.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

NavigationLink.defaultProps = {
  className: '',
  text: '',
  to: '',
  onClick: () => {},
};

export default NavigationLink;
