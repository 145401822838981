import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { process } from "@progress/kendo-data-query";
import ReportLayout from "./ReportLayout";
import ReportCard from "./ReportCard";
import TransvoiceGrid from "./TransvoiceGrid";
import { ChartContainer } from "../Charts";
import {
  setDatasourceCrossFilter,
  removeDatasourceCrossFilter,
  updateTablesOfReports,
} from "../../actions";
import { numberFormat } from "../../../Share/utils/reportUtil";
import { ThreeDotLoader } from "../../../Share/components";
import { ReportCardNew } from "ReportPortal/components";
import moment from "moment";

const propTypes = {
  dashboardDisplay: PropTypes.bool,
  reportDetail: PropTypes.shape({}).isRequired,
  selectedAgreement: PropTypes.shape({}).isRequired,
  reportDateRange: PropTypes.shape({}).isRequired,
  dataReadyFlags: PropTypes.shape({}).isRequired,
  reportId: PropTypes.number.isRequired,
  OnChartFiltered: PropTypes.func.isRequired,
  onRemoveChartFilter: PropTypes.func.isRequired,
  onDashboardChartItemclicked: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  dashboardFilter: PropTypes.shape({}).isRequired,
  onColumnStructUpdate: PropTypes.func.isRequired,
  dateRange: PropTypes.shape({}).isRequired,
};

const defaultProps = {
  data: [],
  onDashboardChartItemclicked: () => {},
  dashboardDisplay: false,
};

const INITIAL_STATES = {
  reportId: 0,
  excelFileName: "sheet",
  chartType: "line",
  tables: [],
  secondData: [],
};

class Report extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATES,
      dashboard: { ...props.reportDetail.dashboard },
      tables: [...props.reportDetail.tables],
      reportId: props.reportId,
      excelFileName: props.reportDetail.excelFileName,
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...prevState,
      data: nextProps.data,
      tables: [...nextProps.reportDetail.tables],
    };
  }
  setFilterInfo = (rows) => {
    const { selectedAgreement, dateRange } = this.props;

    return [
      {
        type: "title",
        cells: [{ value: selectedAgreement.name }],
      },
      {
        type: "title",
        cells: [
          {
            value:
              // eslint-disable-next-line max-len
              `${moment(dateRange.start).format("YYYY-MM-DD HH:mm")} - ${moment(
                dateRange.end
              ).format("YYYY-MM-DD HH:mm")}`,
          },
        ],
      },
      ...rows,
    ];
  };

  processGroupByData = (data, dataState) => {
    const procesData = process(data, dataState);
    const returnData = [];
    procesData.data.forEach((element) => {
      const returnElement = {};
      returnElement[dataState.group[0].field] = element.value;
      this.extractKeyValue(returnElement, element);
      const aggregatesKeys = [...Object.keys(element.aggregates)];
      aggregatesKeys.forEach((aggrItem) => {
        returnElement[aggrItem] = element.aggregates[aggrItem].sum;
      });
      Object.keys(returnElement).forEach(
        (key) => returnElement[key] === undefined && delete returnElement[key]
      );
      returnData.push(returnElement);
    });
    return returnData;
  };

  extractKeyValue = (returnObj, element) => {
    if (element.items && element.items.length > 0) {
      element.items.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        returnObj[item.field] = item.value;
        this.extractKeyValue(returnObj, item);
      });
    }
    return returnObj;
  };

  processGroupByProperty = (
    aggregateOn,
    aggregateElement,
    groupbyInterpreter = [],
    tableId
  ) => {
    const processData = [];
    const columns = [];
    groupbyInterpreter.forEach((element) => {
      const processElement = {};
      processElement[aggregateOn] = element.value;
      element.items.forEach((item) => {
        processElement[item.value] = item.aggregates[aggregateElement]
          ? item.aggregates[aggregateElement].sum
          : "0.00";

        if (columns.indexOf(item.value) < 0) {
          columns.push(item.value);
        }
      });
      processData.push(processElement);
    });
    this.processTableColumns(columns.sort(), tableId);
    return processData;
  };

  processDistributionPerProperty = (
    aggregateOn,
    aggregateElements = [],
    groupbyInterpreter = [],
    tableId
  ) => {
    const processData = [];
    const columns = [];
    const distributeColumns = [];
    groupbyInterpreter.forEach((element) => {
      const processElement = {};
      processElement[aggregateOn] = element.value;
      element.items.forEach((item) => {
        aggregateElements.forEach((aggregateElement) => {
          const aggregateKey = `${item.value}-${aggregateElement}`;
          processElement[aggregateKey] = item.aggregates[aggregateElement]
            ? item.aggregates[aggregateElement].sum
            : "0.00";
          if (columns.indexOf(aggregateKey) < 0) {
            columns.push(aggregateKey);
          }
          if (distributeColumns.indexOf(item.value) < 0) {
            distributeColumns.push(item.value);
          }
        });
      });
      processData.push(processElement);
    });
    this.processTableColumns(columns, tableId, distributeColumns.sort());
    return processData;
  };

  processData = (data) =>
    this.state.tables.map((x) => {
      switch (x.type) {
        case "property group": {
          return this.processGroupByProperty(
            x.columGroup.aggregateOn,
            x.columGroup.aggregateElements[0],
            process(data, x.groupTable).data,
            x.id
          );
        }
        case "distribute property group": {
          return this.processDistributionPerProperty(
            x.columGroup.aggregateOn,
            x.columGroup.aggregateElements,
            process(data, x.groupTable).data,
            x.id
          );
        }
        default: {
          return this.processGroupByData(data, x.groupTable);
        }
      }
    });

  totalFooterCell = (props, tableData, tableId) => {
    if (props.field !== "orderUnitName") {
      const total = tableData[tableId].reduce(
        (acc, current) =>
          acc +
          (current[props.field] ? Number(current[props.field].toFixed(0)) : 0),
        0
      );

      return (
        <td
          colSpan={props.colSpan}
          style={{
            ...props.style,
            color: "#000",
            textAlign: "right",
            paddingRight: "5%",
          }}
        >
          {numberFormat(total) || 0}
        </td>
      );
    }
    return (
      <td
        colSpan={props.colSpan}
        style={{
          ...props.style,
          color: "#000",
          textAlign: "right",
          paddingRight: "5%",
        }}
      >
        {""}
      </td>
    );
  };

  handleTableColumns = (columnStruct, tableId) => {
    const newtableState = [...this.state.tables];
    const newStateOfTable = {
      ...this.state.tables.filter((x) => x.id === tableId)[0],
      columnStruct,
    };
    newtableState.splice(tableId, 1, { ...newStateOfTable });
    this.props.onColumnStructUpdate(this.state.reportId, newtableState);
  };

  processTableColumns = (columns, tableId, distributionColumns) => {
    const { tables } = this.state;
    const returnColumnStruct = [tables[tableId].columnStruct[0]];
    if (distributionColumns) {
      distributionColumns.forEach((dColumn) => {
        const matchChangedObj = tables[tableId].columnStruct.filter(
          (column) => column.title === dColumn
        );
        returnColumnStruct.push({
          showOnGrid: matchChangedObj[0] ? matchChangedObj[0].showOnGrid : true,
          title: dColumn,
          subColumn: columns
            .filter((x) => x.includes(dColumn))
            .map((column) => {
              const matchChangedSubObj = matchChangedObj[0]
                ? matchChangedObj[0].subColumn.filter((x) => x.field === column)
                : [];
              return {
                field: column,
                showOnGrid: matchChangedSubObj[0]
                  ? matchChangedSubObj[0].showOnGrid
                  : true,
                format: "{0:n0}",
                className: "text-float-right",
                title: column,
                width: `${
                  document.documentElement.clientWidth / (columns.length + 2)
                }px`,
                sortable: true,
              };
            }),
        });
      });
    } else {
      columns.forEach((x) => {
        const matchChangedObj = tables[tableId].columnStruct.filter(
          (column) => column.field === x
        );
        returnColumnStruct.push({
          field: x,
          showOnGrid: matchChangedObj[0] ? matchChangedObj[0].showOnGrid : true,
          title: x,
          width: `${
            document.documentElement.clientWidth / (columns.length + 2)
          }px`,
          sortable: true,
        });
      });
    }
    tables[tableId] = { ...tables[tableId], columnStruct: returnColumnStruct };
    this.state = {
      ...this.state,
      tables,
    };
  };

  exportRef = [];

  handleExport = (exporter, id) => {
    this.exportRef[id] = exporter;
  };

  saveExcel = (id, isSaveAll = false) => {
    const { tables } = this.state;
    let mainExport = {};
    if (!isSaveAll) {
      mainExport = this.exportRef[id].workbookOptions();
      mainExport.sheets[0].title = `${tables[id].excelSheetName}-${id}`;
      mainExport.sheets[0].rows = [
        ...this.setFilterInfo(mainExport.sheets[0].rows),
      ];
      this.exportRef[id].save(mainExport);
    } else {
      mainExport = this.exportRef[0].workbookOptions();
      mainExport.sheets[0].title = `${tables[id].excelSheetName}`;
      mainExport.sheets[0].rows = [
        ...this.setFilterInfo(mainExport.sheets[0].rows),
      ];
      this.exportRef.slice(1).forEach((x, i) => {
        const tempSheet = x.workbookOptions().sheets[0];
        tempSheet.title = `${tables[i + 1].excelSheetName}`;
        tempSheet.rows = [...this.setFilterInfo(tempSheet.rows)];

        mainExport.sheets[i + 1] = tempSheet;
      });
      this.exportRef[0].save(mainExport);
    }
  };

  handleChartItemclicked = (event) => {
    const { reportId, OnChartFiltered, onRemoveChartFilter, dashboardFilter } =
      this.props;
    const compareKey =
      event.series.categoryField || event.target.props.seriesType;
    const comapreValue = event.category || event.text;
    if (dashboardFilter.key !== undefined) {
      if (
        dashboardFilter.key === compareKey &&
        dashboardFilter.value === comapreValue
      ) {
        onRemoveChartFilter();
      } else {
        onRemoveChartFilter();
        OnChartFiltered(reportId, { key: compareKey, value: comapreValue });
      }
    } else {
      onRemoveChartFilter();
      OnChartFiltered(reportId, { key: compareKey, value: comapreValue });
    }
  };

  handleGridRowClick = (event) => {
    const { reportId, OnChartFiltered, onRemoveChartFilter, dashboardFilter } =
      this.props;
    // eslint-disable-next-line no-underscore-dangle
    const compareKey = event.target._columns[0].field;
    const comapreValue = event.dataItem[compareKey];
    if (dashboardFilter.key !== undefined) {
      if (
        dashboardFilter.key === compareKey &&
        dashboardFilter.value === comapreValue
      ) {
        onRemoveChartFilter();
      } else {
        onRemoveChartFilter();
        OnChartFiltered(reportId, { key: compareKey, value: comapreValue });
      }
    } else {
      onRemoveChartFilter();
      OnChartFiltered(reportId, { key: compareKey, value: comapreValue });
    }
  };

  render() {
    const { reportId, tables, excelFileName, dashboard } = this.state;
    const {
      data,
      dashboardDisplay,
      reportDateRange,
      onDashboardChartItemclicked,
      dataReadyFlags,
      dateRange,
    } = this.props;

    const processData = data;
    const footerCellRender = (props, tableId) =>
      this.totalFooterCell(props, processData, tableId);

    return dashboardDisplay ? (
      ""
    ) : (
      <React.Fragment>
        <ReportLayout
          reportId={reportId}
          saveExcel={this.saveExcel}
          dateRange={dateRange}
        >
          {this.props.reportDetail.emptyMessage && (
            <span className="report__empty-message">
              {this.props.reportDetail.emptyMessage}
            </span>
          )}
          {tables.map((table, i) =>
            !table.useNew ? (
              <ReportCard
                key={table.id}
                title={table.title}
                columnStruct={table.columnStruct}
                setColumnStruct={(columnStruct) =>
                  this.handleTableColumns(columnStruct, table.id)
                }
                style={table.style}
                handleTypechange={this.handleTypechange}
                handleExport={() => this.saveExcel(table.id)}
              >
                {dataReadyFlags[i] ? (
                  <React.Fragment>
                    <TransvoiceGrid
                      handleExport={(e) => this.handleExport(e, table.id)}
                      excelFileName={excelFileName}
                      onGridRowClick={this.handleGridRowClick}
                      columnStruct={[
                        table.columnStruct[0],
                        ...table.columnStruct.slice(1).map((column) =>
                          table.type !== "distribute property group"
                            ? {
                                ...column,
                                footerCell: (props) =>
                                  footerCellRender(props, table.id),
                              }
                            : {
                                ...column,
                                subColumn: column.subColumn.map((x) => ({
                                  ...x,
                                  footerCell: (props) =>
                                    footerCellRender(props, table.id),
                                })),
                              }
                        ),
                      ]}
                      data={processData[table.id]}
                    />
                  </React.Fragment>
                ) : (
                  <ThreeDotLoader />
                )}
                <ChartContainer
                  data={processData[table.id]}
                  chartType="column"
                  reportId={reportId}
                  onChartItemclicked={this.handleChartItemclicked}
                  {...table.chart}
                />
              </ReportCard>
            ) : (
              <ReportCardNew
                table={table}
                saveExcel={() => this.saveExcel(table.id)}
                handleExport={(e) => this.handleExport(e, table.id)}
                excelFileName={excelFileName}
                totalFooterCell={this.totalFooterCell}
                data={processData[table.id]}
                originalColumnStruct={[...table.columnStruct]}
                isDataLoaded={dataReadyFlags[i]}
              />
            )
          )}
        </ReportLayout>
      </React.Fragment>
    );
  }
}

Report.propTypes = propTypes;
Report.defaultProps = defaultProps;

const mapStateToProps = (state, props) => {
  const data = [...state.reportDataSources[props.reportId].crossFilterData];
  const dataReadyFlags = [
    ...(state.reportDataSources[props.reportId].dataReadyFlags || []),
  ];
  return {
    data,
    dataReadyFlags,
    dashboardFilter: state.app.dashboardFilter,
    selectedAgreement: state.app.selectedAgreement,
    reportDateRange: state.reportDetails.reports[props.reportId].range,
  };
};

const mapDispatchToProps = (dispatch) => ({
  OnChartFiltered: (reportId, filter) => {
    dispatch(setDatasourceCrossFilter(reportId, filter));
  },
  onRemoveChartFilter: () => {
    dispatch(removeDatasourceCrossFilter());
  },
  onColumnStructUpdate: (reportId, tables) => {
    dispatch(updateTablesOfReports(reportId, tables));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
