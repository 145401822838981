import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

const SettingMenuDropdown = props => {
  const { children, button, className } = props;
  const [showDropDown, setShowDropDown] = useState(false);

  const toggleMenu = () => {
    setShowDropDown(!showDropDown);
  };

  const onBlur = event => {
    const { currentTarget } = event;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        toggleMenu();
      }
    }, 250);
  };

  return (
    <span onBlur={onBlur} className={className}>
      <div
        role="button"
        tabIndex="-1"
        onClick={toggleMenu}
        onKeyDown={() => {}}
      >
        {button}
      </div>
      {showDropDown && children}
    </span>
  );
};

SettingMenuDropdown.propTypes = {
  children: PropTypes.node,
  button: PropTypes.node,
  className: PropTypes.string,
};

SettingMenuDropdown.defaultProps = {
  children: null,
  button: null,
  className: 'tv-filter-list__element',
};

export default SettingMenuDropdown;
