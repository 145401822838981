/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";
import sv from "date-fns/locale/sv";
registerLocale("sv-SE", sv);
const MonthRangePicker = ({
  placeholder,
  onChange,
  className,
  searchDateRange,
}) => {
  const [dateRange, setDateRange] = useState([
    moment(searchDateRange.start).toDate(),
    moment(searchDateRange.end).toDate(),
  ]);
  const [startDate, endDate] = dateRange;
  const handleChange = (event) => {
    setDateRange(event);
    onChange(event, "datePicker");
  };
  return (
    <div className={`tv-daterangepicker ${className}`}>
      <label htmlFor="datenum">Datum</label>
      <ReactDatePicker
        selectsRange
        dateFormat="MMMM yyyy"
        startDate={startDate}
        endDate={endDate}
        showMonthYearPicker
        showFullMonthYearPicker
        minDate={moment().subtract(4, "year").toDate()}
        maxDate={moment().endOf("month").toDate()}
        locale="sv-SE"
        calendarStartDay={1}
        onFocus={(e) => e.target.blur()}
        disabledKeyboardNavigation
        onChange={handleChange}
        placeholderText={placeholder}
      />
    </div>
  );
};

MonthRangePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  searchDateRange: PropTypes.shape({
    start: PropTypes.shape({}),
    end: PropTypes.shape({}),
  }),
};
MonthRangePicker.defaultProps = {
  placeholder: "",
  className: "",
  searchDateRange: PropTypes.shape({
    start: moment().startOf("year").subtract(4, "month"),
    end: moment().startOf("year").add(1, "month"),
  }),
};

export default MonthRangePicker;
