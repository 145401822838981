import { process } from "@progress/kendo-data-query";
import { LOCAL_STORE_LANGUAGE } from "../constants/localStorage";

let lang = localStorage.getItem(LOCAL_STORE_LANGUAGE);
switch (lang) {
  case "en":
    lang = "en-Us";
    break;
  case "se":
    lang = "sv-Se";
    break;
  default:
    lang = "sv-Se";
    break;
}
export function processGroupByData(data, dataState) {
  const procesData = process(data, dataState);

  const returnData = [];
  procesData.data.forEach((element) => {
    const returnElement = {};
    returnElement[dataState.group[0].field] = element.value;
    Object.keys(element.aggregates).forEach((aggrItem) => {
      returnElement[aggrItem] = element.aggregates[aggrItem].sum;
    });
    returnData.push(returnElement);
  });
  return returnData;
}

export function kFormatter(numberToFormatted) {
  if (numberToFormatted.includes(",")) return 0;
  return Math.abs(numberToFormatted) > 999
    ? `${Number(
        Math.sign(numberToFormatted) *
          (Math.abs(numberToFormatted) / 1000).toFixed(0)
      ).toLocaleString(lang, { style: "decimal" })} t`
    : `${Math.sign(numberToFormatted) * Math.abs(numberToFormatted)}`;
}
export function numberFormat(numberToFormatted, fixpoint = 0) {
  return fixpoint === 0
    ? Number(numberToFormatted.toFixed(0)).toLocaleString(lang)
    : numberToFormatted.toLocaleString(lang, { style: "decimal" });
}

export function summationBasedOnKey(dataList, key) {
  return (
    dataList.reduce(
      (acc, current) => acc + (current[key] ? current[key] : 0),
      0
    ) || 0
  );
}
