import moment from "moment";
import { Translate } from "../../Share/components";
import * as actionTypes from "../actions/ActionTypes";

const INITIAL_STATE = {
  range: {
    from: moment().subtract(2, "month").startOf("month").toDate(),
    to: moment().subtract(1, "month").startOf("month").toDate(),
  },
  widgets: [
    {
      reportId: 11,
      type: 0,
      gridArea: "pieCard0",
      dataSources: 10,
      description: "",
      categoryField: "orderStatus",
      field: "noOfAssignments",
      title: "Utfall inkomna order​",
      tableSourceId: 0,
    },

    {
      type: 1,
      reportId: 3,
      gridArea: "tableCard0",

      dataSources: 1,
      categoryField: "skill",
      tableMainField: "Språk",
      field: "noOfAssignments",
      title: Translate({ content: "report.distributionPerSkill" }),
      tableSourceId: 0,
    },
    {
      type: 1,
      tableMainField: "Kompetens",
      reportId: 4,
      gridArea: "tableCard1",
      dataSources: 1,
      categoryField: "competenceLevel",
      field: "noOfAssignments",
      title: Translate({ content: "report.distributionPerCompetence" }),
      tableSourceId: 0,
    },
    {
      reportId: 2,
      type: 0,
      dataSources: 1,
      gridArea: "pieCard1",
      description: "",
      categoryField: "interpretationType",
      field: "noOfAssignments",
      title: "Fördelning per tolkmetod",
      tableSourceId: 0,
    },
  ],
};

const app = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RESET_APP:
      return INITIAL_STATE;
    case actionTypes.SET_DASHBOARD_DATERANGE: {
      const { range } = action;
      return {
        ...state,
        range,
      };
    }
    default:
      return state;
  }
};

export default app;
