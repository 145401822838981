import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IfComponent from '../IfComponent';
import "./style.scss";

const propTypes = {
  isVisible: PropTypes.bool,
};

const defaultProps = {
  isVisible: false,
};

const LoadingOverlay = ({ isVisible }) => (
  <IfComponent
    condition={isVisible}
    whenTrue={(
      <div className="tv-loading__wrapper">
        <div className="tv-loading__content">
          <div className="tv-loading__spinner" />
        </div>
      </div>
    )}
  />
);

LoadingOverlay.propTypes = propTypes;
LoadingOverlay.defaultProps = defaultProps;

const mapStateToProps = state => ({
  isVisible: state.app.showLoadingOverlay,
});


export default connect(mapStateToProps, null)(LoadingOverlay);
