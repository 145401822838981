import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ValidatorForm } from "react-form-validator-core";
import { Translate, TransvoiceButton } from "../../../../Share/components";
import { login } from "../../../actions";
import { TextField } from "../../../components";
import "./style.scss";
import LoadingOverlay from "ReportPortal/components/atoms/LoadingOverlay";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerName: "",
      password: "",
      submitDisabled: true,
      loading: false,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  loadingClose = () => {
    this.setState({ loading: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { customerName, password } = this.state;
    this.setState({ loading: true });
    this.props.onLogin(customerName, password, this.loadingClose);
  };

  handleValidatorListener = () => {
    this.form.isFormValid().then((isValid) => {
      this.setState({ submitDisabled: !isValid });
    });
  };

  render() {
    const { loading } = this.state;
    return (
      <div className="tv-login-form__container">
        <ValidatorForm
          // eslint-disable-next-line no-return-assign
          ref={(node) => (this.form = node)}
          onSubmit={this.handleSubmit}
          onError={this.handleValidatorListener}
        >
          <h4 className="tv-login-form__header">Logga in</h4>
          <div className="tv-field__container">
            <div className="tv-field__label">
              <Translate content="general.userName" />
            </div>
            <TextField
              validatorListener={this.handleValidatorListener}
              onChange={this.handleChange}
              name="customerName"
              maxLength={50}
              value={this.state.customerName}
              placeholder={Translate({ content: "login.enterYourUserName" })}
              validators={["required"]}
              errorMessages={[
                Translate({ content: "login.userNameIsRequire" }),
                Translate({ content: "login.userNameIsNotValid" }),
              ]}
            />
          </div>
          <div className="tv-field__container">
            <div className="tv-field__label">
              <Translate content="general.password" />
            </div>
            <TextField
              type="password"
              validatorListener={this.handleValidatorListener}
              onChange={this.handleChange}
              name="password"
              value={this.state.password}
              placeholder={Translate({ content: "login.enterYourPassword" })}
              validators={["minStringLength:8"]}
              errorMessages={[Translate({ content: "login.minLengthMessage" })]}
            />
          </div>
          <TransvoiceButton
            type="primary"
            buttonType="submit"
            text={Translate({ content: "login.login" })}
            className="tv-login__form-submit"
            onClick={this.handleSubmit}
            disabled={this.state.submitDisabled}
          />
        </ValidatorForm>
        <LoadingOverlay isVisible={loading} />
      </div>
    );
  }
}
Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLogin: (customerName, password, callback) => {
    dispatch(login(customerName, password, callback));
  },
});

export default connect(null, mapDispatchToProps)(Login);
