import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TopNavBar } from "../components";
import { LoadingOverlay, Logo } from "../../Share/components";
import history from "../../Share/utils/history";
import { onGetUserData } from "../actions";

const propTypes = {
  children: PropTypes.element.isRequired,
};

const defaultProps = {};

class MainLayout extends Component {
  constructor(props) {
    super(props);
    history.listen((location) => {});
  }

  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    return (
      <React.Fragment>
        {/* <LoadingOverlay /> */}

        <TopNavBar
          customerName=""
          onLogout={() => {
            history.push("/login");
          }}
        />
        <div>{this.props.children}</div>
      </React.Fragment>
    );
  }
}

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoad: () => {
    dispatch(onGetUserData());
  },
});

export default connect(null, mapDispatchToProps)(MainLayout);
